import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

const AboutHero = ({ title, media }) => {
  return (
    <StyledAboutHero>
      <VerticalSection
        critical
        title={title}
        titleAs="h1"
        media={media}
        mobileFull
      />
    </StyledAboutHero>
  )
}

const StyledAboutHero = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);

  img {
    width: 100% !important;
  }
`

export default AboutHero
