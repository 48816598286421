import React from "react"
import styled from "styled-components"

import { device, featureGlass } from "utils"
import { VerticalSection, ValueProp, Grid } from "components/common"

import arrow from "src/images/common/arrow.svg"

const AboutNumbers = ({ title, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      // width="100%"
      align="center"
      list={valueProps}
      gap={56}
      mobileGap="40px"
      col={3}
      // toEdge
      component={(vp) => (
        <ValueProp
          {...vp}
          media={{ desktop: vp.icon }}
          titleAs="h2"
          align="center"
          className="number-item"
        />
      )}
    />
  )

  return (
    <StyledAboutNumbers arrow={arrow}>
      <VerticalSection critical title={title} media={media} grid={renderGrid} />
    </StyledAboutNumbers>
  )
}

const StyledAboutNumbers = styled.div`
  ${featureGlass};

  .number-item {
    .title {
      font-family: "Roboto Mono", monospace;
      font-size: 2.125rem;
      line-height: 40px;
      color: var(--spruce);
      letter-spacing: -0.02em;

      @media ${device.laptop} {
        font-size: 3rem;
        line-height: 56px;
      }

      .italic {
        font-size: 1.5rem;
        line-height: 27px;
        opacity: 0.5;
      }
    }

    .subtitle {
      a {
        display: block;
        margin-top: var(--sp-8);
        transition: opacity 0.2s ease-in-out;

        &::after {
          content: ${({ arrow }) => `url(${arrow})`};
          font-size: 1.8rem;
          line-height: 1;
          display: inline-block;
          transition: transform 0.2s ease-in-out;

          transform: translateY(3px);
          margin-left: 4px;
        }

        &:hover {
          opacity: 0.8;

          &::after {
            transform: translate3d(4px, 3px, 0px);
          }
        }
      }
    }
  }
`

export default AboutNumbers
