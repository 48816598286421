import React from "react"
import styled from "styled-components"

import { Section, Testimonials, TextGroup } from "components/common"

import { featureGlass, useContentfulComponent, useInView } from "utils"

const EmployeeTestimonials = ({ title, components }) => {
  const { testimonials } = useContentfulComponent(components)
  const { ref, isVisible } = useInView()

  return (
    <StyledEmployeeTestimonials ref={ref}>
      <Section align="center">
        <TextGroup title={title} align="center" />
        <Testimonials testimonials={testimonials} isVisible={isVisible} />
      </Section>
    </StyledEmployeeTestimonials>
  )
}

const StyledEmployeeTestimonials = styled.div`
  ${featureGlass};
  display: flex;
  align-items: center;
  justify-content: center;

  /* --section-padding-top: 0; */
  /* --section-mobile-padding-top: 80px; */

  .testimonials {
    margin-top: var(--sp-32);
    align-self: center;

    .testimonial-logo {
      border-radius: 50%;
      &.active {
        box-shadow: var(--card-shadow);
      }
    }
  }
`

export default EmployeeTestimonials
