import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { Section, TextGroup, Media, Cta } from "src/components/common"

import TimelineTick from "src/images/about/timeline_tick.inline.svg"

import { device } from "utils"
import classNames from "classnames"

const BackboneTimeline = ({ title, media, valueProps }) => {
  const renderTimeline = () =>
    map(valueProps, (vp) => <TimeLineItem key={vp.id} {...vp} />)

  return (
    <StyledBackboneTimeline>
      <Section>
        <TextGroup title={title} className="section-title mobile" />
        <div className="timelineMax">
          <TextGroup title={title} className="section-title desktop" />
          <Media
            media={{ desktop: media.desktop }}
            className="timeline_graphic"
          />
          <ul className="timeline">{renderTimeline()}</ul>
        </div>
      </Section>
    </StyledBackboneTimeline>
  )
}

const StyledBackboneTimeline = styled.div`
  --section-padding-top: 0;
  --section-mobile-padding-top: var(--sp-48);
  --section-max-width: 1080px;
  --section-mobile-padding-left: 0;

  .section-title {
    &.mobile {
      border: 1px soild red;

      .title {
        --tg-text-align: center;
        text-align: center;
      }

      @media ${device.laptop} {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @media ${device.laptop} {
        position: absolute;
        display: inline-block;
        left: 200px;
        top: 125px;
      }
    }

    .title {
      text-align: center;
    }
  }

  .timelineMax {
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;

    .timeline_graphic {
      position: relative;
      z-index: 2;
    }
    .timeline {
      display: flex;
      /* grid-template-columns: 1fr; */
      flex-direction: column;
      grid-gap: 52px;
      align-items: flex-start;
      margin-top: 50px;

      @media ${device.laptop} {
        margin-top: 235px;
      }
    }
  }
`

const TimeLineItem = (item) => {
  const renderMemberAdded = () => {
    if (item.subtitle) {
      return (
        <div className="membersAdded">
          <Media media={{ desktop: item.icon }} />
          <TextGroup subtitle={item.subtitle} />
        </div>
      )
    }
  }

  return (
    <StyledTimeLineItem className={classNames({ cta: item.ctas })}>
      <TimelineTick className="tick" />
      <TextGroup
        {...item}
        hatAs="h2"
        titleAs={item.ctas ? "h2" : "p"}
        subtitle=""
        custom={renderMemberAdded}
        // subtitleAs="p"
        cta={item.ctas ? () => <Cta ctas={item.ctas} /> : undefined}
      />
    </StyledTimeLineItem>
  )
}

const StyledTimeLineItem = styled.li`
  position: relative;
  margin-left: 16px;

  --tg-max-width: 673px;

  @media ${device.laptop} {
    margin-left: 80px;
  }
  .tick {
    display: none;
    @media ${device.laptop} {
      display: inline-block;
      position: absolute;
      left: 0;
      transform: translateX(-82.55px);
      top: 20px;
    }
  }

  .hat {
    font-family: "Roboto Mono", monospace;
    font-size: 2.125rem;
    line-height: 40px;
    --tg-hat-color: var(--spruce);
    letter-spacing: -0.02em;

    @media ${device.laptop} {
      font-size: 3rem;
      line-height: 56px;
    }
  }

  .title {
    white-space: break-spaces;
    /* 16px */
    font-size: 1rem;
    line-height: 24px;

    @media ${device.laptop} {
      /* 20px */
      font-size: 1.25rem;
      line-height: 32px;
    }
  }
  .subtitle {
    font-weight: 500;
  }

  &.cta {
    .title {
      font-size: 2.125rem;
      line-height: 40px;
      color: var(--spruce);
      letter-spacing: -0.02em;

      @media ${device.laptop} {
        font-size: 3rem;
        line-height: 56px;
      }
    }
  }

  .membersAdded {
    display: flex;
    align-items: center;
    .tgWrap {
      margin-left: var(--sp-8);
    }
  }
`

export default BackboneTimeline
