import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav, VerticalSection } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import {
  AboutNumbers,
  AboutUsHero,
  AboutUsTeam,
  AboutWhoWeAre,
  BackboneTimeline,
  EmployeeTestimonials,
} from "../sections/about_us"

import { CtaGetDemo } from "components/cta"

const AboutUsPage = ({ data: { about_us } }) => {
  const {
    about_us_hero,
    about_who_we_are,
    about_numbers,
    backbone_timeline,
    employee_testimonials,
    about_us_team,
    about_us_members_of,
    cta_get_demo,
  } = useContentfulSection(about_us.sections)

  const meta = useMeta(about_us)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <AboutUsHero {...about_us_hero} />
        <AboutWhoWeAre {...about_who_we_are} />
        <AboutNumbers {...about_numbers} />
        <BackboneTimeline {...backbone_timeline} />
        <EmployeeTestimonials {...employee_testimonials} />
        <AboutUsTeam {...about_us_team} />
        <VerticalSection
          className="proud_members"
          align="center"
          title={about_us_members_of.title}
          media={about_us_members_of.media}
        />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  .proud_members {
    img {
      width: 100%;
    }
  }
`

export const query = graphql`
  {
    about_us: contentfulPage(pageId: { eq: "about_us" }) {
      ...Page
    }
  }
`

export default AboutUsPage
