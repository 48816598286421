import React from "react"
import styled from "styled-components"
import { VerticalSection } from "components/common"

const AboutWhoWeAre = ({ title, subtitle }) => {
  return (
    <StyledAboutWhoWeAre>
      <VerticalSection critical title={title} subtitle={subtitle} />
    </StyledAboutWhoWeAre>
  )
}

const StyledAboutWhoWeAre = styled.div`
  --tg-max-width: 850px;
  --section-padding-top: 0;
  --section-mobile-padding-top: 0;

  .subtitle {
    white-space: break-spaces;
  }
`

export default AboutWhoWeAre
