import React from "react"
import styled from "styled-components"

import { VerticalSection, Cta } from "components/common"

import { featureGlass, device } from "utils"

const AboutUsTeam = ({ hatIcon, hat, title, subtitle, media, ctas }) => {
  return (
    <StyledAboutUsTeam>
      <VerticalSection
        hatIcon={hatIcon}
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        cta={() => <Cta theme={[{ isLink: true }]} ctas={ctas} />}
        imageOrder="1"
        tgOrder="2"
        mobileImageOrder="1"
        mobileTgOrder="2"
        align="left"
        full
        mobileFull
      />
    </StyledAboutUsTeam>
  )
}

const StyledAboutUsTeam = styled.div`
  ${featureGlass};
  --section-max-width: 1080px;

  --section-padding-top: 0;
  --section-mobile-padding-top: 0;

  @media ${device.laptop} {
    --tg-max-width: 820px;
  }
`

export default AboutUsTeam
